import React, { useCallback, useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import HomepageHero from '../components/homepageHero/homepageHero'
import CompaniesGrid from '../components/companiesGrid/CompaniesGrid'
import indexStyles from './index.module.css'
import Container from '../components/container'
import * as start from './content-hub/start.module.css'
import CardSlider from '../components/hub/cardSlider/cardSlider'
import * as linkStyle from '../styles/components/links.module.css'
import * as styles from '../components/globals-v2.module.css'
import Icon from '../components/icons'
import MeetTheTeam from '../components/meetTheTeam/meetTheTeam'
import ResourcesAndContent from '../components/resourcesAndContent/resourcesAndContent'
import { useWindowSize } from '../hooks/useWindowSize.hook'

export const query = graphql`
    query IndexPageQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            socialLinkedin
            socialInstagram
            socialSubstack
            socialTwitter
        }

        homepage: sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }) {
            title
            featuredName
            featuredNameButton
            featuredDescription
            metaDescription
            metaKeywords
            heroTextNew
            meetTheTeamTitle
            meetTheTeamImage {
                asset {
                    _id
                    url
                }
            }
            featuredPost {
                ... on SanityAudio {
                    _type
                    title
                    learnMore
                    slug {
                        current
                    }
                    series {
                        title
                        slug {
                            current
                        }
                    }
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    timeReads
                    appleUrl
                    spotifyUrl
                    youTubeUrl
                }
                ... on SanityVideo {
                    _type
                    title
                    learnMore
                    slug {
                        current
                    }
                    series {
                        title
                        slug {
                            current
                        }
                    }
                    videoId
                    youtubePlayer
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    featureImage {
                        asset {
                            _id
                            url
                        }
                    }
                    timeReads
                    appleUrl
                    spotifyUrl
                    youTubeUrl
                }
                ... on SanityWritten {
                    _id
                    _type
                    learnMore
                    publishedAt
                    openLink
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    title
                    blurb
                    slug {
                        current
                    }
                    featureImage {
                        asset {
                            url
                            _id
                        }
                    }
                }
                ... on SanityPost {
                    _type
                    id
                    publishedAt
                    mainImage {
                        asset {
                            url
                            _id
                        }
                        alt
                    }
                    title
                    link
                    timeReads
                }
            }
            quickNews {
                ... on SanityAudio {
                    _type
                    title
                    learnMore
                    slug {
                        current
                    }
                    series {
                        title
                        slug {
                            current
                        }
                    }
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    timeReads
                    appleUrl
                    spotifyUrl
                    youTubeUrl
                }
                ... on SanityVideo {
                    _type
                    title
                    learnMore
                    slug {
                        current
                    }
                    series {
                        title
                        slug {
                            current
                        }
                    }
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    videoId
                    youtubePlayer
                    featureImage {
                        asset {
                            _id
                            url
                        }
                    }
                    timeReads
                    appleUrl
                    spotifyUrl
                    youTubeUrl
                }
                ... on SanityWritten {
                    _id
                    _type
                    learnMore
                    publishedAt
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    title
                    slug {
                        current
                    }
                    featureImage {
                        asset {
                            url
                            _id
                        }
                    }
                }
                ... on SanityPost {
                    _type
                    id
                    publishedAt
                    mainImage {
                        asset {
                            url
                            _id
                        }
                        alt
                    }
                    title
                    link
                    timeReads
                }
            }
            leftColumn {
                ... on SanityAudio {
                    _type
                    title
                    learnMore
                    slug {
                        current
                    }
                    series {
                        title
                        slug {
                            current
                        }
                    }
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    timeReads
                    appleUrl
                    spotifyUrl
                    youTubeUrl
                }
                ... on SanityVideo {
                    _type
                    title
                    learnMore
                    slug {
                        current
                    }
                    series {
                        title
                        slug {
                            current
                        }
                    }
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    featureImage {
                        asset {
                            _id
                            url
                        }
                    }
                    timeReads
                    appleUrl
                    spotifyUrl
                    youTubeUrl
                }
                ... on SanityWritten {
                    _id
                    _type
                    publishedAt
                    learnMore
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    title
                    _rawContent
                    slug {
                        current
                    }
                    openLink
                    featureImage {
                        asset {
                            url
                            _id
                        }
                    }
                }
                ... on SanityPost {
                    _type
                    id
                    publishedAt
                    mainImage {
                        asset {
                            url
                            _id
                        }
                        alt
                    }
                    title
                    link
                    timeReads
                }
            }
            rightColumn {
                ... on SanityAudio {
                    _type
                    title
                    learnMore
                    slug {
                        current
                    }
                    series {
                        title
                        slug {
                            current
                        }
                    }
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    timeReads
                    appleUrl
                    spotifyUrl
                    youTubeUrl
                }
                ... on SanityVideo {
                    _type
                    title
                    learnMore
                    slug {
                        current
                    }
                    series {
                        title
                        slug {
                            current
                        }
                    }
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    featureImage {
                        asset {
                            _id
                            url
                        }
                    }
                    timeReads
                    appleUrl
                    spotifyUrl
                    youTubeUrl
                }
                ... on SanityWritten {
                    _id
                    _type
                    publishedAt
                    learnMore
                    tags {
                        title
                        slug {
                            current
                        }
                    }
                    title
                    openLink
                    _rawContent
                    slug {
                        current
                    }
                    featureImage {
                        asset {
                            url
                            _id
                        }
                    }
                }
                ... on SanityPost {
                    _type
                    id
                    publishedAt
                    mainImage {
                        asset {
                            url
                            _id
                        }
                        alt
                    }
                    title
                    link
                    timeReads
                }
            }
        }

        featuredVideo: sanityVideo(featured: {eq: true}) {
            _id
            _type
            publishedAt
            tags {
                title
                slug {
                    current
                }
            }
            title
            learnMore
            slug {
                current
            }
            videoId
            youtubePlayer
            featureImage {
                asset {
                    _id
                    url
                }
                hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                }
            }
        }

        featuredWritten: sanityWritten(featured: {eq: true}) {
            _id
            _type
            publishedAt
            tags {
                title
                slug {
                    current
                }
            }
            title
            blurb
            openLink
            learnMore
            slug {
                current
            }
            featureImage {
                asset {
                    url
                    _id
                }
                hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                }
            }
        }

        companies: allSanityCompany(filter: {display: {ne: false}}, sort: {fields: [sortOrder, investmentDate, title], order: [DESC, DESC, ASC]}) {
            edges {
                node {
                    title
                    _id
                    _key
                    sortOrder
                    investmentDate
                    slug {
                        current
                    }
                    _rawExcerpt
                    link
                    logoColor {
                        asset {
                            _id
                            url
                        }
                    }
                    logo {
                        asset {
                            _id
                            url
                        }
                    }
                    brandHex {
                        hex
                    }
                    brandGradient {
                        useGradient
                        colors {
                            color {
                                hex
                            }
                            colorPoint
                        }
                        gradientDirection
                    }
                    chooseLogo
                    mainImage {
                        asset {
                            _id
                            url
                        }
                    }
                    sectors {
                        _id
                        title
                    }
                    stage {
                        _id
                        title
                    }
                }
            }
        }

        social: allSanitySocial (sort: {order: DESC, fields: publishedAt}) {
            nodes {
                url
                title
                platform
                description,
                featureImage: image {
                    asset {
                        _id
                        url
                    }
                }
            }
        }
    }
`

const IndexPage = ({ data, errors }) => {
  const social = data.social.nodes
  const { width } = useWindowSize()
  const [calcToRender, setCalcToRender] = useState(width < 1024 ? 12 : 17)
  const [companiesToRender, setCompaniesToRender] = useState(width < 1024 ? 12 : 17)
  const [lastFeatured, setLastFeatured] = useState(null)

  const handleLoadMore = useCallback(() => {
    setCompaniesToRender(companiesToRender + width < 1024 ? 12 : 17)
  }, [companiesToRender])

  useEffect(() => {
    if (width <= 1024) {
      setCalcToRender(12)
      setCompaniesToRender(12)
    }
    if (width > 1024) {
      setCalcToRender(17)
      setCompaniesToRender(17)
    }
  }, [width])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  const homepage = (data || {}).homepage
  const companyNodes = (data || {}).companies
    ? mapEdgesToNodes(data.companies).filter(filterOutDocsWithoutSlugs)
    : []
  const sortedCompanies =
    companyNodes && companyNodes.filter(company => company.sortOrder !== null)
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  useEffect(() => {
    const allFeatured = [data.featuredWritten, data.featuredVideo].filter(Boolean).filter(node => !node._id.startsWith('drafts.'))
    if (allFeatured.length === 0) {
      setLastFeatured(null)
      return
    }

    allFeatured.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))

    setLastFeatured(allFeatured[0])
  }, [data.featuredWritten, data.featuredVideo])

  return (
    <Layout palette='redHead' page='home'>
      <SEO title={homepage.title} description={homepage.metaDescription} keywords={homepage.metaKeywords} />
      <h1 hidden>{homepage.title}</h1>

      <HomepageHero title={homepage.heroTextNew} />

      {sortedCompanies && (
        <div style={{ marginBottom: width > 768 ? 80 : 40 }}>
          <div className={`${styles.companiesWrapper} slideTop`}>
            <div className={indexStyles.companiesTitle}>Companies</div>
            <CompaniesGrid
              page='home'
              buttonName='View All'
              companies={sortedCompanies} companiesToRender={companiesToRender} calcToRender={calcToRender}
              handleLoadMore={handleLoadMore}
            />
          </div>
        </div>
      )}

      <Container class={`${styles.container} ${styles.sliderOverflow}`}>
        <section className={styles.socialContainer}>
          <MeetTheTeam title={homepage?.meetTheTeamTitle} image={homepage?.meetTheTeamImage} />
        </section>
      </Container>
      <Container class={`${styles.container} ${styles.sliderOverflow}`}>
        <section className={styles.socialContainer}>
          <ResourcesAndContent featuredNameButton={homepage?.featuredNameButton} featuredName={homepage?.featuredName} featuredDescription={homepage.featuredDescription} rightColumn={homepage.rightColumn} featuredPost={homepage?.featuredPost ? homepage?.featuredPost : lastFeatured} leftColumn={homepage.leftColumn} quickNews={homepage?.quickNews} name='Resources & Content' />
        </section>
      </Container>

      <Container class={`${styles.container} ${styles.sliderOverflow} ${styles.backgroundMaroon} ${styles.colorMilk}`}>
        <section className={styles.socialContainer}>
          <div className={`${start.oneColumn} ${start.red}`} style={{marginTop: '20px'}}>
            <CardSlider page='home' title='Social' type='social' data={social} settings={{ slidesToShow: 4 }} alternate />
          </div>
        </section>
      </Container>
      <div style={{ backgroundColor: '#8d1b1c', display: width > 768 ? 'block' : 'none' }}>
        <Container class={`${styles.defaultPaddings} ${styles.socialButtonsWrapper} ${styles.backgroundMaroon} ${styles.homepage}`}>
          <Link to='https://www.youtube.com/@redpoint_ventures' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Youtube
            <span>
              <div className={linkStyle.iconCircle} />
              <div className={`${linkStyle.icon} ${styles.icon}`}>
                <Icon symbol='newsArrowIcon' color='currentColor' />
              </div>
            </span>
          </Link>
          <Link to='https://www.instagram.com/redpoint/?hl=en' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Insta
            <span>
              <div className={linkStyle.iconCircle} />
              <div className={`${linkStyle.icon} ${styles.icon}`}>
                <Icon symbol='newsArrowIcon' color='currentColor' />
              </div>
            </span>
          </Link>
          <Link to='https://www.tiktok.com/@redpoint?lang=en' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>TikTok
            <span>
              <div className={linkStyle.iconCircle} />
              <div className={`${linkStyle.icon} ${styles.icon}`}>
                <Icon symbol='newsArrowIcon' color='currentColor' />
              </div>
            </span>
          </Link>
          <Link to='https://www.linkedin.com/company/redpointventures' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>LinkedIn
            <span>
              <div className={linkStyle.iconCircle} />
              <div className={`${linkStyle.icon} ${styles.icon}`}>
                <Icon symbol='newsArrowIcon' color='currentColor' />
              </div>
            </span>
          </Link>
          <Link to='https://x.com/redpoint' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>X
            <span>
              <div className={linkStyle.iconCircle} />
              <div className={`${linkStyle.icon} ${styles.icon}`}>
                <Icon symbol='newsArrowIcon' color='currentColor' />
              </div>
            </span>
          </Link>
          {/* <Link to='https://open.spotify.com/show/5WqBqDb4br3LlyVrdqOYYb' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Spotify */}
          {/*   <span> */}
          {/*     <div className={linkStyle.iconCircle} /> */}
          {/*     <div className={`${linkStyle.icon} ${styles.icon}`}> */}
          {/*       <Icon symbol='newsArrowIcon' color='currentColor' /> */}
          {/*     </div> */}
          {/*   </span> */}
          {/* </Link> */}
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
