import React, { useRef, useEffect } from 'react'
import Lottie from 'lottie-react'
import heroAnimation from '../../animations/redpoint-hero-animation.json'
import mobileHeroAnimation from '../../animations/redpoint-hero-animation-mobile.json'

import styles from './homepageHero.module.css'
import headerStyles from '../../components/header.module.css'
// eslint-disable-next-line import/no-unresolved
import { useWindowSize } from '../../hooks/useWindowSize.hook'
import linkStyle from '../../styles/components/links.module.css'

const HomepageHero = ({ title }) => {
  const animationRef = useRef(null)
  const { width } = useWindowSize()

  useEffect(() => {
    let wasHidden = false
    let isAnimating = false
    let scrollY = 0

    const hero = document.querySelector(`.${styles.homepageHero}`)
    const companyBlock = document.querySelector('.slideTop')

    const disableScroll = () => {
      scrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
      document.body.style.width = '100%'
    }

    const enableScroll = () => {
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, scrollY)
    }

    const handleScroll = (e) => {
      requestAnimationFrame(() => {
        const currentScrollY = window.scrollY

        if (isAnimating) {
          window.scrollTo(0, scrollY)
          return
        }

        if (currentScrollY > 0 && !wasHidden) {
          wasHidden = true
          isAnimating = true
          hero.classList.add(styles.hidden)
          companyBlock.classList.add('slide')
          document.getElementsByTagName('header')[0].classList.remove(headerStyles.redHead)

          disableScroll()

          setTimeout(() => {
            enableScroll()
            isAnimating = false
          }, 1000)

        } else if (currentScrollY === 0 && wasHidden && !isAnimating) {
          wasHidden = false
          hero.classList.remove(styles.hidden)
          companyBlock.classList.remove('slide')
          document.getElementsByTagName('header')[0].classList.add(headerStyles.redHead)
        }
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollDown = () => {
    window.scrollBy(0, 1)
  }

  return (
    <>
      <div className={styles.homepageHero}>
        <div className={styles.animationWrap} ref={animationRef}>
          <Lottie
            animationData={width > 768 ? heroAnimation : mobileHeroAnimation}
            loop
            autoplay
            onLoopComplete={scrollDown}
          />
        </div>
        <div className={styles.heroSkipBtn}>
          <button
            type='submit'
            className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.skipButton}`}
            onClick={scrollDown}
          >Skip
            <span>
              <div className={`${linkStyle.leftArrow} ${linkStyle.iconRed}`}>
                <svg width='24' height='27' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M11.9108 27L0.417969 14.1458L1.91188 12.7344L10.978 23.5847L10.7732 1.59982e-05L13.0483 -5.5209e-07L12.8587 23.5847L21.9247 12.7344L23.418 14.1458L11.9108 27Z'
                    fill='currentColor'
                  />
                </svg>
              </div>
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

export default HomepageHero
