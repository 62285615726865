import React from 'react'
import styles from './meetTheTeam.module.css'
import { Link } from 'gatsby'
import * as linkStyle from '../../styles/components/links.module.css'
import Icon from '../icons'
import { Image } from '../Image'

const MeetTheTeam = ({ title, image }) => {
  return (
    <Link to='/our-people/' className={`${styles.meetTheTeam} ${linkStyle.linkArrow}`}>
      <div className={styles.meetTheTeamTitleContainer}>
        <div className={styles.meetTheTeamTitle}>{title}</div>
        <button className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>
          <span className={styles.iconContainer} style={{ transform: 'scale(2)' }}>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='#FF0E1D' />
            </div>
          </span>
        </button>
      </div>

      <div className={styles.meetTheTeamImageWrap}>
        <Image imgObj={image} props={{ alt: 'Meet the team' }} />
      </div>
    </Link>
  )
}

export default MeetTheTeam
