import React, { useEffect, useRef, useState } from 'react' // , { useState, useEffect }
import Slider from 'react-slick'
import Card from '../card/card'
import Icon from '../../icons'
import { useWindowDimensions } from '../../../lib/helpers'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from './cardSlider.module.css'

const CardSlider = ({ type, data, alternate, settings, title, page }) => {
  // const randHash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  // const { width } = useWindowDimensions()
  // const [currentWidth, setWidth] = useState(width);

  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideCount, setSlideCount] = useState(0)
  const sliderRef = useRef()

  // useEffect(() => {
  //     const handleResize = () => {
  //         const width = window.innerWidth;
  //         setWidth(width);
  //     }

  //     if (typeof window !== 'undefined') {
  //         window.addEventListener('resize', handleResize);
  //     }
  // }, []);

  useEffect(() => {
    setSlideCount(Math.floor(sliderRef.current.innerSlider.props.children.length - sliderRef.current.innerSlider.props.slidesToShow))
  }, [])

  // const PrevArrow = (props) => {
  //   const { onClick } = props
  //   if (type === 'visual') {
  //     return (
  //       <span className={`${styles.sliderLeft}`} onClick={onClick}><Icon symbol='chevronright' /></span>
  //     )
  //   }
  //
  //   return (
  //     <span className={`${styles.sliderLeft}`} onClick={onClick}><Icon symbol='arrowLeft' /></span>
  //   )
  // }
  //
  // const NextArrow = (props) => {
  //   const { onClick } = props
  //   if (type === 'visual') {
  //     return (
  //       <span className={`${styles.sliderRight}`} onClick={onClick}><Icon symbol='chevronright' /></span>
  //     )
  //   }
  //
  //   return (
  //     <span className={`${styles.sliderRight}`} onClick={onClick}><Icon symbol='arrowRight' /></span>
  //   )
  // }

  const PrevArrow = ({ onClick }) => (
    <span className={`${styles.sliderLeft} ${currentSlide === 0 ? styles.disabled : ''}`} onClick={onClick}><Icon symbol='newChevronRight' /></span>
  )

  const NextArrow = ({ onClick }) => (
    <span className={`${styles.sliderRight} ${currentSlide === slideCount ? styles.disabled : ''}`} onClick={onClick}><Icon symbol='newChevronRight' /></span>
  )

  const defaultSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  if (type === 'visual') {
    defaultSettings.slidesToShow = 1
    defaultSettings.centerMode = true
    defaultSettings.variableWidth = true
    defaultSettings.infinite = true
    defaultSettings.dots = true
    defaultSettings.responsive = []
  }

  if (type === 'audio') {
    defaultSettings.slidesToShow = 2
  }

  if (type === 'social') {
    defaultSettings.autoplay = false
    defaultSettings.infinite = false
  }

  const outputSettings = { ...defaultSettings, ...settings }

  // console.log(outputSettings);
  // console.log(type);

  return (
    <div className={`${styles.slider} ${styles[`sliderType${type}`]}`}>
      {title && (
        <div style={page === 'home' ? { color: '#FFF', fontSize: 24 } : {}} className={styles.heading}>{title}</div>
      )}
      <div style={{}}>
        <Slider {...outputSettings} ref={sliderRef}>
          {data && data.map((card, cardIndex) => {
            // console.log(data);
            if (type === 'audio' && cardIndex % 2 === 1) return null

            return (
              <div className={styles.sliderCard} key={cardIndex}>
                {type === 'audio' ? (
                  <>
                    <Card page={page} type={type} data={card} />
                    <Card page={page} type={type} data={data[cardIndex + 1]} />
                  </>
                ) : (
                  <>
                    {/* {(type === 'visual') && (
                                        <Card type='simple' data={card} short={true} display={currentWidth < 768 ? true : false} />
                                    )} */}
                    <Card page={page} type={type} data={card} options={{ followBtn: false, stats: false, red: true, desc: true }} short={(alternate && (cardIndex % 2 === 1))} /> {/*  display={(type === 'visual' && currentWidth < 768) ? false : true} */}
                  </>
                )}
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default CardSlider
